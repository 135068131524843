<template>
    <div class="login-container">
        <h2 class="title">
            <i class="icon-user"></i>{{$t('user.resetPwd')}}
        </h2>
        <form @submit.prevent>
            <div class="form-item">
                <input type="text" v-model="user.email" :placeholder="$t('user.email')" autocomplete="off" disabled/>
                <transition name="fade-in">
                    <p class="error-tips" v-if="errorTips.email">{{errorTips.email}}</p>
                </transition>
            </div>
            <div class="form-item">
                <input type="password" v-model="user.password" autocomplete="off" :placeholder="$t('user.newPwd')" />
                <transition name="fade-in">
                    <p class="error-tips" v-if="errorTips.password">{{errorTips.password}}</p>
                </transition>
            </div>
            <div class="form-item">
                <input type="password" v-model="user.password2" autocomplete="off" :placeholder="$t('user.confirmPwd')" />
                <transition name="fade-in">
                    <p class="error-tips" v-if="errorTips.password2">{{errorTips.password2}}</p>
                </transition>
            </div>
            <div class="form-item">
                <button class="button long" @click="submit">{{$t('common.submit')}}</button>
            </div>
        </form>
    </div>
</template>
<script>
import schema from 'async-validator';
import { changePassword } from '../services/user';
const emailReg = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export default {
    name: 'change-password',
    data () {
        const descriptor = {
            email: [{
                type: 'string',
                required: true,
                message: this.$t('user.enterEmail'),
            }, {
                pattern: emailReg,
                message: this.$t('user.enterLegalEmail'),
            }],
            password: [{
                type: 'string',
                required: true,
                message: this.$t('user.enterPassword'),
            }, {
                validator: (rule, value) => value.length >= 6,
                message: this.$t('user.enterLegalPassword'),
            }],
            password2: {
                type: 'string',
                required: true,
                validator: (rule, value) => value.length >= 6 && value === this.user.password2,
                message: this.$t('user.confirmPassword'),
            }
        }
        return {
            user: {
                email: this.$route.query.email || '',
                password: '',
                password2: '',
                code: this.$route.query.code || '',
            },
            validator: new schema(descriptor),
            errorTips: {
                email: '',
                password: '',
                password2: '',
            }
        }
    },
    mounted () {
        
    },
    methods: {
        submit () {
            this.validator.validate(this.user).then(async () => {
                changePassword(this.user)
                .then(res => {
                    if(res.code === 0) {
                        this.$success({
                            title: this.$t('user.resetSuccess'),
                            onOk: () => {
                                this.$router.push('/login');
                            }
                        })
                    }else {
                        this.$message.error(errorMsg[res.code]);
                    }
                })
                .catch(err => {
                    console.log(err);
                })
            }).catch(({ errors, fields }) => {
                let errorTips = {}
                errors.forEach(error => {
                    if(!errorTips[error.field]) {
                        errorTips[error.field] = error.message;
                    }
                })
                this.errorTips = errorTips;
            })
        }
    }
}
</script>
<style lang="less" scoped>
@import "../assets/css/theme.less";
.login-container {
    width: 500px;
    margin: 60px auto;
    border: 1px solid #f1f1f1;
    border-radius: 3px;
    h2 {
        font-size: 20px;
        padding: 20px 40px;
        i {
            margin-right: 10px;
        }
    }
}
.form-item {
    padding: 0 40px;
    margin-bottom: 20px;
    position: relative;
    input {
        width: 100%;
        padding: 0 10px;
        background-color: #fff;
        height: 40px;
        border: 1px solid #dcdfe6;
        border-radius: 4px;
        &:-webkit-autofill {
            box-shadow: 0 0 0px 1000px white inset !important;
        }
        &:focus {
            border-color: @primary-color;
        }
        &:disabled {
            background: #f5f7fa;
            border-color: #e4e7ed;
            color: #c0c4cc;
            cursor: not-allowed;
        }
    }
    .error-tips {
        position: absolute;
        left: 40px;
        font-size: 12px;
        padding-top: 4px;
        color: @red-color;
    }
}
.long {
    width: 100%;
    margin: 0;
}
</style>